import React, { useState } from 'react';
import { easeInOut, motion } from 'framer-motion';
import AnimCursor from '../components/AnimCursor';

function DigitalMarketing() {
    const [showContent, setShowContent] = useState(false); // State to manage visibility

  const containerVariants = {
    hidden: { 
      opacity: 0, 
      x: '100vw',
      transition: {
        staggerChildren: 0.5,
      } 
    },
    visible: { 
      opacity: 1, 
      x: 0,
      transition: { 
        type: 'tween',
        staggerChildren: 0.4,
        when: "beforeChildren",
      }
    },
  };

  const homeDotPatter = {
    visible:{
      scale:[1,1.3],
      transition:{
        duration:15,
        repeat:Infinity,
        repeatType:"reverse",
        ease:easeInOut
      }
    },
    initial:{
      scale:1
    }
  };

  // Variants for text fade-in animation
  const textVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: {
        duration: 1.5, // 1 second fade-in
      },
    },
  };
  return (
    <>
    <AnimCursor/>
    <motion.div 
         className='container-fluid' 
         id="digitalMarketing"  
         variants={containerVariants}
         initial="hidden"
         animate="visible"
         exit="exit"
         onAnimationComplete={() => setShowContent(true)} // Trigger visibility
       >
         <div className='container-fluid ph-0 mh-5' style={{ position: "inherit", zIndex: 1 }}>
           <div className='row d-flex align-items-center vh-100'>
           {showContent && ( // Conditionally animate text after containerVariants completes
            <>
            <div className='row pt-5 mt-5 d-flex align-items-stretch'>
            <motion.h2 variants={textVariants} initial="hidden" animate="visible" className='mb-5 fs-1 text-purple'>Digital Marketing</motion.h2>
            
            
              
                   
                  
                   <motion.div className='col-md-3 mb-3' variants={textVariants} initial="hidden" animate="visible">
                    <div className='digital-card h-100'>
                        <h3>SEO</h3>
                        <p>
                        We concentrate on beneficial SEO practices a never-ending process which
                        demands to be kept under watch, analyzed, and tweaked to become in line with the
                        ever-changing algorithms of search engines.
                        </p>
                    </div>
                   </motion.div>

                   <motion.div className='col-md-3 mb-3' variants={textVariants} initial="hidden" animate="visible">
                    <div className='digital-card h-100'>
                        <h3>Performace Marketing</h3>
                        <p>
                        We are creating using the data-driven approach to achieve
measurable results. Using each of the mentioned channels, specific actions can be
stimulated; that include click-through, lead generation, conversion or sales, and other
desired customer actions
                        </p>
                    </div>
                   </motion.div>

                   <motion.div className='col-md-3 mb-3' variants={textVariants} initial="hidden" animate="visible">
                    <div className='digital-card h-100'>
                        <h3>Content Marketing</h3>
                        <p>
                        We create and distribute content to attract and retain a defined
audience. A specialized content marketing strategy is created based on; clear goals,
knowing whom to target, and what to produce and distribute.
                        </p>
                    </div>

                    
                   </motion.div>
                   <motion.div className='col-md-3 mb-3' variants={textVariants} initial="hidden" animate="visible">
                    <div className='digital-card h-100'>
                        <h3>Email Marketing</h3>
                        <p>
                        Marketing: Email marketing is a leading channel for businesses and one
through which we communicate with customers to solicit sales, loyalty, and other
responses
                        </p>
                    </div>

                    
                   </motion.div>
                
                   </div>
             </>
            )}
            
           </div>
          </div>
        
       </motion.div>
   </>
  )
}

export default DigitalMarketing